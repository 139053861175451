@use '../abstracts' as *;

.timeline {
  height: 70vh;
  overflow-y: scroll;

  .date {
    font-size: 0.85rem;
    background-color: $color-green;
    color: $color-white;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 12px;
  }

  .icon {
    background: #22c6ab;
    text-align: center;
    padding-top: 16px;
    @include responsive(lg) {
      padding-top: 10px;
      & > p {
        font-size: 0.75rem;
      }
    }

    & > p {
      color: $color-white;
    }
  }
}
