@use '../abstracts' as *;

.inbox {
  h1 {
    margin-bottom: 20px;
  }

  .messages {
    border: 1px solid $color-grey;
    height: 80vh;

    .message {
      @include flex;
      padding: 10px 20px;
      border-bottom: 1px solid $color-grey;
      cursor: pointer;

      &:hover {
        background-color: $color-grey-light;
      }

      p {
        font-weight: 500;
      }

      .from {
        @include flex;
        justify-content: flex-start;
        width: 20%;
        min-width: fit-content;
        white-space: nowrap;

        img {
          margin-right: 10px;
          width: 40px;
        }
      }

      .title {
        flex: 1;
        padding: 0 25px;
        overflow: hidden;

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        @include responsive(sm) {
          padding-right: 0;
        }
      }

      .date {
        width: 20%;
        font-size: 0.8rem;
        min-width: fit-content;
        white-space: nowrap;
        @include responsive(sm) {
          display: none;
        }

        p {
          text-align: right;
        }
      }
    }
  }

  .body {
    display: grid;
    place-items: center;
    height: 75vh;
    border: 1px solid $color-grey;

    .nothing {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }

  .msg-wrapper {
    height: 80vh;
    border: 1px solid $color-grey;
    overflow-y: scroll;
    padding: 40px;
    @include responsive(sm) {
      padding: 20px;
    }

    .title-wrapper {
      @include flex;
      justify-content: flex-start;
      margin-bottom: 40px;

      & > div {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }

    .content {
      line-height: 2rem;
    }
  }
}

.loader-wrapper {
  display: grid;
  place-items: center;
  height: 70vh;
}
