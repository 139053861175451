@use '../abstracts' as *;

.admin {
  h1 {
    margin-bottom: 40px;
  }

  .loader-wrapper {
    display: grid;
    place-items: center;
    height: 90vh;
  }

  .tab-wrapper {
    width: fit-content;
    margin-bottom: 40px;
  }

  .kpis {
    margin-top: 50px;

    .select-wrapper {
      width: 90%;
      min-width: 260px;
      max-width: 600px;
      margin-bottom: 40px;
    }

    .detail {
      span {
        font-weight: 600;
      }
    }

    .complete {
      color: $color-green;
      font-weight: 600;
    }

    .incomplete {
      color: $color-red;
      font-weight: 600;
    }

    .grid {
      display: grid;
      place-items: center;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 60px;
      column-gap: 40px;
      margin-top: 40px;
      @include responsive(xl) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include responsive(lg) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(sm) {
        grid-template-columns: repeat(1, 1fr);
      }

      .kpi {
        display: grid;
        width: 250px;
        height: 200px;
        border-radius: 22px;
        cursor: pointer;
        padding: 20px;
        background-color: $color-primary;

        p {
          color: $color-white;
          span {
            font-weight: 600;
          }
        }
      }
    }

    .success {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      color: $color-green;
    }

    .form-wrapper {
      padding: 0 20px;

      & > * {
        margin-bottom: 20px;
      }
    }
  }

  .tokens {
    .token {
      margin-bottom: 40px;

      p {
        margin-bottom: 5px;
        span {
          font-weight: 600;
        }
      }

      .verified {
        color: $color-green;
        font-weight: 600;
      }
    }
  }

  .refunds {
    .refund {
      margin-bottom: 40px;

      p {
        margin-bottom: 5px;

        span {
          font-weight: 600;
        }
      }

      .approved {
        color: $color-green;
        font-weight: 600;
      }
    }
  }
}
