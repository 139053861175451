@use '../abstracts' as *;

.sidebar {
  flex: 1;
  background-color: $color-primary;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  position: relative;

  .header {
    padding: 20px 0;
    height: 60px;
    margin-bottom: 40px;

    img {
      width: 150px;
      display: block;
      margin: auto;
    }
  }

  .profile {
    width: 100%;
    height: 120px;
    display: grid;
    place-items: center;
    margin-bottom: 40px;

    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 10px;
    }

    .default-avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;

      img {
        width: 100%;
      }
    }

    .name {
      color: #acacac;
      margin-bottom: 5px;
      text-transform: capitalize;
      text-align: center;
    }
  }

  .links {
    width: 100%;
  }

  .sidebar-link {
    @include flex;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    padding: 5px 10%;
    cursor: pointer;

    &:hover,
    &.active {
      background: $color-secondary;
    }

    .icon {
      margin-right: 15%;
    }

    p {
      color: $color-white;
      font-size: 0.9rem;
    }

    .carat {
      margin-left: auto;
      width: 7px;
    }
  }

  .logout {
    position: absolute;
    bottom: 20px;
    width: 100%;
  }
}
