@use '../abstracts' as *;

.overview {
  padding-bottom: 50px;

  &.scroll {
    max-height: 70vh;
    overflow-y: scroll;
  }

  h1 {
    margin-bottom: 20px;
  }

  .note {
    color: $color-red;
    margin-bottom: 20px;
  }

  .card-wrapper {
    @include flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .cagr-wrapper {
    margin-bottom: 40px;
    height: 400px;

    @include responsive(md) {
      height: 1200px;
    }
  }

  .line-chart-wrapper {
    @include flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    .line-chart {
      margin-bottom: 20px;
      width: 24%;
      @include responsive(md) {
        width: 48%;
      }
      @include responsive(sm) {
        width: 100%;
      }
    }
  }

  .area-chart-wrapper {
    @include flex;
    margin-bottom: 40px;
    @include responsive(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    & > * {
      width: 49%;
      @include responsive(md) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .product-list-wrapper {
    border: 1px solid $color-grey;
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: initial;
    scrollbar-width: initial;

    ::-webkit-scrollbar {
      display: initial;
    }

    .title {
      padding: 20px;
    }
  }
}
