@use '../abstracts' as *;

.product-info {
  height: 70vh;
  border: 1px solid $color-grey;
  overflow: scroll;

  .headings {
    @include flex;
    margin-bottom: 10px;

    h3 {
      flex: 1;
      border-top: 1px solid $color-grey;
      border-bottom: 1px solid $color-grey;
      padding: 5px 10px;
      display: grid;
      place-items: center;
      min-width: 120px;
      height: 64px;
    }
  }

  .content {
    @include flex;
    align-items: flex-start;

    & > div {
      flex: 1;
      padding: 5px 10px;
      min-width: 120px;

      p {
        font-size: 0.9rem;
        margin-bottom: 15px;
      }
    }

    .plan {
      text-transform: capitalize;
      font-weight: 600;
    }

    .amount {
      font-weight: 600;
      font-size: 1.5rem;
    }
  }
}
