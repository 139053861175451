@use '../../abstracts' as *;

.textInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  .label {
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 5px;
  }

  .input-wrapper {
    @include flex;
    width: 100%;
    border: 1px solid #d9d9d9b3;

    & > .img {
      background-color: #f8f9fa;
      border-right: 1px solid #d9d9d9b3;
      height: 45px;
      width: 20%;
      display: grid;
      place-items: center;
    }

    & > input {
      font-family: 'Poppins';
      height: 45px;
      background-color: $color-white;
      border: none;
      outline: none;
      padding: 5px 10px;
      width: 100%;

      &.msg {
        height: 120px;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
      }
    }

    textarea {
      width: 100%;
    }
  }
  & > .error {
    color: $color-red;
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 5px;
  }
}
