@use '../abstracts' as *;

.modal {
  position: fixed;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  place-items: center;

  .modalContent {
    width: 90%;
    max-width: 420px;
    background-color: white;
    padding: 20px;
    border-radius: 5px;

    .close {
      width: 30px;
      height: 30px;
      display: flex;
      margin-left: auto;
      margin-bottom: 20px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }
}
