@use '../abstracts' as *;

.upload-pan {
  .card-wrapper {
    @include flex;
    justify-content: center;
    margin-bottom: 60px;
    @include responsive(md) {
      flex-direction: column;
    }

    img {
      max-width: 400px;
      width: 100%;
      object-fit: contain;
    }
  }
  .upload-wrapper {
    @include flex;
    justify-content: center;
    @include responsive(md) {
      flex-direction: column;
    }

    .space {
      width: 50px;
      height: 50px;
    }

    .upload {
      min-width: 270px;
      width: 40%;
      margin-bottom: 20px;
      @include responsive(md) {
        width: 100%;
      }
      p {
        font-weight: 500;
        margin-bottom: 20px;
      }

      .dropzone {
        border: 1px dashed $color-grey;
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10%;
        cursor: pointer;

        .preview {
          width: 100%;
          height: 180px;
          object-fit: contain;
        }

        .pic {
          margin-bottom: 20px;
          width: 30px;
        }

        p {
          font-size: 0.85rem;
          text-align: center;
        }
      }
    }
  }

  .btn-wrapper {
    display: block;
    width: fit-content;
    margin-left: 80px;
    @include responsive(md) {
      margin-left: 0;
    }
  }

  .success {
    font-size: 0.85rem;
    color: $color-green;
    font-style: italic;
    margin-top: 10px;
  }
}
