$color-primary: #333d54;
$color-primary-dark: #2b3241;
$color-secondary: linear-gradient(90deg, #f64c3b, #b80cb6);
$color-text: #283145;
$color-text-light: #6c6c6c;
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #d9d9d9;
$color-grey-light: #f8f9fa;
$color-red: #d92d75;
$color-pink: #eb415133;
$color-green: #22c6ab;
$color-green-light: #22c6ab33;
$color-orange: #ef6e6e;
$color-yellow: #ffbe3c;
