@use '../abstracts' as *;

.investment {
  & > .heading {
    @include flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    .back {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      cursor: pointer;
      @include responsive(sm) {
        width: 30px;
        height: 30px;
      }

      img {
        width: 100%;
      }
    }

    & > h1 {
      font-size: 1.5rem;
      @include responsive(sm) {
        font-size: 1rem;
      }

      span {
        &.complete {
          color: $color-green;
        }
        &.incomplete {
          color: $color-red;
        }
      }
    }
  }

  .note {
    color: $color-red;
    margin-bottom: 20px;
  }

  .tab-wrapper {
    height: 10vh;
    max-width: 100vw;
    overflow-x: scroll;
  }
}

.loader-wrapper {
  height: 100vh;
  display: grid;
  place-items: center;
}
