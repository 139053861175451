@use '../abstracts' as *;

.loader {
  display: block;
  margin: auto;
  width: fit-content;

  div {
    width: 25px;
    height: 25px;
    border: 4px solid $color-red;
    border-radius: 50%;
    animation: rotation 1500ms infinite linear;
    border-top-color: transparent;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
