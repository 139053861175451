@use '../abstracts' as *;

.payment-form {
  text-align: center;
  padding: 0 20px;
  @include responsive(sm) {
    padding: 0;
  }

  h2 {
    margin-bottom: 20px;
  }

  .note {
    margin-bottom: 40px;

    span {
      color: $color-red;
      font-weight: 600;
    }
  }

  .amount {
    @include flex;
    margin-bottom: 20px;

    .number {
      font-weight: 600;
    }
  }
}
