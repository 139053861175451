@use '../../abstracts' as *;

.select-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;

  .label {
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 5px;
  }

  select {
    font-family: inherit;
    height: 45px;
    background-color: $color-white;
    border: 1px solid #d9d9d9b3;
    padding: 5px 10px;
    width: 100%;
  }

  & > .error {
    color: $color-red;
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 5px;
  }
}
