@use '../abstracts' as *;

.sign-in-card {
  background-color: $color-white;
  position: relative;

  .header {
    background-color: $color-primary;
    height: 60px;
    width: 100%;
    display: grid;
    place-items: center;

    img {
      width: 150px;
    }
  }

  .body {
    padding: 20px 40px 40px;
    text-align: center;
    @include responsive(sm) {
      padding: 20px 20px 40px;
    }

    .title {
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    & > .text {
      color: $color-text-light;
      font-size: 0.75rem;
      margin-bottom: 20px;
    }

    .space {
      width: 20px;
      height: 10px;
    }

    .forgot-pwd {
      width: fit-content;
      display: block;
      margin-left: auto;
      margin-bottom: 20px;
    }

    .success {
      color: $color-green;
      font-size: 0.8rem;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .error {
      color: $color-red;
      font-size: 0.8rem;
      font-weight: 500;
      margin-top: 5px;
    }

    .signup {
      a {
        color: $color-red;
      }
    }

    .power {
      color: $color-text-light;
      font-size: 0.7rem;
    }
  }

  .footer {
    position: absolute;
    height: 20px;
    width: 100%;
    bottom: 0;
    background-color: $color-primary;
  }
}
