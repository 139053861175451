@use '../abstracts' as *;

.legal-docs-chart {
  padding: 10px 20px;
  border: 1px solid $color-grey;
  height: 350px;

  .bar-wrapper {
    @include flex;
    width: 60%;
    margin: auto;
  }
}
