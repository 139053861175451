@use '../abstracts' as *;

.product-grid {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  @include responsive(xl) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include responsive(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include responsive(690px) {
    grid-template-columns: repeat(1, 100%);
  }

  .fund {
    border: 1px solid $color-grey;
    width: 270px;
    height: 330px;
    @include flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;

    p {
      font-size: 1.2rem;
      font-weight: 600;
      color: $color-grey;
      margin-top: 40px;
    }
  }
}
