@use '../abstracts' as *;

h1 {
  font-size: 1.8rem;
  font-weight: 700;
}

h3 {
  font-size: 1.1rem;
  font-weight: 600;
}

a {
  color: $color-red;
}

h1,
h3,
p {
  color: $color-text;
}
