@use '../abstracts' as *;

.linear-progress {
  @include flex;
  flex-direction: column;
  justify-content: flex-start;

  .percentage {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .bar {
    position: relative;
    width: 20px;
    height: 200px;
    background-color: #f8f9fa;
    margin-bottom: 10px;

    .progress {
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .doc {
    font-size: 0.75rem;
    color: $color-text-light;
    font-weight: 500;
    text-transform: uppercase;
  }
}
