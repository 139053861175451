@use '../abstracts' as *;

.line-chart {
  width: 220px;
  height: 300px;
  border: 1px solid $color-grey;
  position: relative;

  .details {
    position: absolute;
    @include flex;
    padding: 10px;
    width: 100%;
    z-index: -100;

    p {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .percentage {
      background-color: $color-grey;
      border-radius: 12px;
      padding: 2px 8px;
      font-size: 0.75rem;
      font-weight: 500;

      &.positive {
        color: #22c6ab;
        background-color: $color-green-light;
      }

      &.negative {
        color: $color-red;
        background-color: $color-pink;
      }
    }
  }

  .recharts-responsive-container {
    height: 75% !important;
    &.blur {
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);
    }
  }

  .info {
    border-top: 1px solid $color-grey;
    background-color: $color-white;
    padding: 10px;
    height: 25%;

    .title {
      font-weight: 600;
      font-size: 0.9rem;
    }

    .date {
      color: $color-text-light;
      font-size: 0.85rem;
    }
  }

  .custom-tooltip {
    background-color: $color-white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}
