@use '../abstracts' as *;

.product {
  border: 1px solid $color-grey;
  width: 270px;
  height: 330px;
  cursor: pointer;

  .img-wrapper {
    width: 80%;
    height: 70%;
    margin: auto;

    img {
      object-fit: contain;
      width: 100%;
      max-height: 90%;
      margin-top: 5%;
    }
  }

  .info {
    @include flex;
    height: 30%;
    border-top: 1px solid $color-grey;
    padding: 10px;

    h3 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .plan {
      text-transform: capitalize;
    }

    .icon {
      width: 35px;
      height: 35px;

      img {
        width: 35px;
      }
    }
  }
}
