@use '../abstracts' as *;

.contact {
  h1 {
    margin-bottom: 80px;
  }

  .wrapper {
    @include flex;
    align-items: flex-start;

    & > * {
      width: 50%;
      padding: 0 5%;
    }

    .form {
      h3 {
        margin-bottom: 20px;
      }

      .btn-wrapper {
        margin-top: 20px;
        width: fit-content;
        margin-left: auto;
      }
    }

    .info {
      margin-top: 100px;

      & > * {
        margin-bottom: 20px;

        span {
          font-weight: 600;
        }
      }
    }
  }
}
