@use '../abstracts' as *;

.header-component {
  display: none;

  @include responsive(md) {
    @include flex;
    position: sticky;
    z-index: 1000;
    top: 0;
    width: 100%;
    background-color: $color-primary;
    height: 10vh;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0 20px;
  }

  .menu-wrapper {
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .drawer {
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    z-index: 1100;
    /* adds animation for all transitions */
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &.opened {
      right: 0;
    }

    & > .sidebar-wrapper {
      position: relative;
      display: grid;
      margin-left: auto;
      z-index: 1200;
      width: 99vw;
      max-width: 250px;
      height: 100vh;

      & > .sidebar {
        position: absolute;
        right: 0;
        width: 100%;
      }
    }
  }
}
