@use '../abstracts' as *;

.area-chart {
  border: 1px solid $color-grey;
  height: 350px;
  @include flex;
  flex-direction: column;
  align-items: flex-start;

  .heading {
    padding: 20px;
  }

  .recharts-responsive-container {
    height: 70% !important;
    &.blur {
      -webkit-filter: blur(3px);
      -moz-filter: blur(3px);
      -o-filter: blur(3px);
      -ms-filter: blur(3px);
      filter: blur(3px);
    }
  }
}
