* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1900px;
  display: block;
  margin: auto;
}

::-webkit-scrollbar {
  display: none;
}

.space {
  width: 50px;
  height: 50px;
}
