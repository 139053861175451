@use '../abstracts' as *;

.legal-docs {
  @include flex;
  @include responsive(lg) {
    flex-direction: column;
    align-items: flex-start;
  }

  .doc-list {
    width: 40%;
    @include flex;
    flex-direction: column;
    @include responsive(lg) {
      width: fit-content;
      flex-direction: column;
      align-items: flex-start;
      height: initial;
      margin: 20px 0;
    }

    .doc {
      @include flex;
      justify-content: flex-start;
      border: 1px solid $color-grey;
      border-right: none;
      width: 100%;
      min-width: 270px;
      height: calc(70vh / 5);
      padding: 0 5%;
      cursor: pointer;
      @include responsive(lg) {
        border-right: 1px solid $color-grey;
        height: 70px;
      }

      &:hover {
        background-color: $color-grey-light;
      }

      .icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;

        img {
          width: 40px;
        }
      }

      .details {
        @include flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      .name-wrapper {
        @include flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 0.85rem;
        margin-bottom: 5px;

        .name {
          text-transform: uppercase;
          margin-right: 20px;
        }
      }

      .bar {
        position: relative;
        height: 7px;
        width: 100%;
        background-color: $color-grey;

        .progress {
          -webkit-transition: all 1s ease-in-out;
          -moz-transition: all 1s ease-in-out;
          -o-transition: all 1s ease-in-out;
          transition: all 1s ease-in-out;
          position: absolute;
          left: 0;
          height: 100%;
        }
      }
    }
  }

  .doc-expanded {
    position: relative;
    height: 70vh;
    width: 60%;
    border: 1px solid $color-grey;
    overflow-y: scroll;
    @include responsive(lg) {
      width: 100%;
    }

    .nothing {
      display: grid;
      place-items: center;
      height: 100%;

      p {
        font-weight: 600;
      }
    }

    .link-wrapper {
      position: sticky;
      top: 0;
      left: 0;
      height: 10%;
      width: 100%;
      z-index: 100;
      padding: 5px;

      a {
        text-decoration: none;
        color: $color-white;
        background-color: $color-primary;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 0.75rem;
        margin-left: auto;
        display: block;
        width: fit-content;
      }
    }

    .pdf-page {
      border-bottom: 1px solid $color-grey;
      padding-bottom: 20px;

      .page-number {
        font-weight: 500;
        font-size: 0.85rem;
        margin-left: 30px;
      }
    }
  }
}
