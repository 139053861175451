@use '../abstracts' as *;

.reports {
  height: 70vh;

  .report {
    @include flex;
    border: 1px solid $color-grey;
    height: 60px;
    padding: 5px 20px;
    cursor: pointer;

    .sender {
      @include flex;
      justify-content: flex-start;
      width: 20%;

      .icon {
        width: 35px;
        height: 35px;
        margin-right: 10px;

        img {
          width: 100%;
        }
      }

      .name {
        font-weight: 600;
      }
    }

    .msg {
      font-weight: 600;
      flex: 1;
    }

    .date {
      font-weight: 600;
      font-size: 0.85rem;
      width: 15%;
    }
  }

  .body {
    display: grid;
    place-items: center;
    height: 100%;
    border: 1px solid $color-grey;

    .nothing {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}
