@use '../abstracts' as *;

.product-list {
  width: 100%;
  min-width: 600px;
  border-collapse: separate;

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      background-color: $color-grey-light;

      tr {
        height: 40px;
      }

      th {
        font-weight: 500;
      }
    }

    tr {
      outline: thin solid $color-grey;

      td {
        &:not(:first-child) {
          -webkit-filter: blur(3px);
          -moz-filter: blur(3px);
          -o-filter: blur(3px);
          -ms-filter: blur(3px);
          filter: blur(3px);
        }
        & > div {
          @include flex;
          justify-content: flex-start;
          padding-left: 20px;

          .icon {
            background-color: $color-orange;
            border-radius: 50%;
            height: 45px;
            width: 45px;
            min-width: 45px;
            min-height: 45px;
            display: grid;
            place-items: center;
            margin-right: 10px;

            p {
              text-transform: uppercase;
              color: $color-white;
              font-weight: 500;
            }
          }

          p {
            font-weight: 600;
            text-align: left;
          }
        }

        .profit {
          font-weight: 600;
        }
      }
    }

    tbody {
      text-align: center;

      tr {
        height: 80px;
        cursor: pointer;
      }
    }
  }
}
