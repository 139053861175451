@use '../../abstracts' as *;

.btn {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &.contained {
    color: $color-white;
    background-color: $color-primary;
  }

  &.outlined {
    color: $color-primary;
    background-color: $color-white;
    border: 2px solid $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &.text {
    color: $color-primary;
    font-weight: 500;
    background-color: transparent;
    border: none;
    font-size: 0.9rem;
  }

  &.medium {
    width: fit-content;
    min-width: 100px;
    height: 36px;
    padding: 5px 15px;
  }

  &.large {
    width: 250px;
    height: 48px;
    padding: 10px 20px;
  }

  &.small {
    width: 100px;
    font-size: 0.8rem;
    width: fit-content;
    padding: 5px 10px;
  }

  &.fullwidth {
    width: 100%;
  }
}
