@use '../abstracts' as *;

.layout {
  display: flex;
  align-items: flex-start;
  @include responsive(md) {
    & > .sidebar {
      display: none;
    }
  }

  .children-wrapper {
    flex: 5;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 5vh 40px;
    @include responsive(lg) {
      flex: 4;
    }
    @include responsive(md) {
      padding: 0;
    }

    .children {
      padding: 0px 0vw;
      @include responsive(md) {
        padding: 20px 5vw;
      }
    }
  }
}
