@use '../abstracts' as *;

.personal-info {
  border: 1px solid $color-grey;
  padding: 20px;

  .row {
    @include flex;
    margin-bottom: 20px;
    @include responsive(700px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .space {
      width: 25px;
      height: 25px;
    }

    &.gender {
      width: 50%;
      @include responsive(xl) {
        width: 70%;
      }
      @include responsive(lg) {
        width: 80%;
      }
      @include responsive(md) {
        width: 100%;
      }
    }

    .upload {
      margin-top: 20px;
      margin-left: 40px;
      position: relative;
      @include responsive(700px) {
        margin-top: 0;
        margin-left: 0;
      }

      label {
        position: absolute;
        left: 0;
        top: 0;
        background-color: $color-primary;
        color: $color-white;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
      }

      input {
        margin-left: 45px;
      }
    }
  }

  .btn-wrapper {
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-top: -40px;
    @include responsive(lg) {
      margin-top: 0px;
    }
    @include responsive(sm) {
      margin-top: 0px;
    }
  }
}
