@use '../abstracts' as *;

.auth {
  background: $color-secondary, url(../../assets/svg/onboarding-bg.svg) center no-repeat;
  background-size: contain;
  background-blend-mode: color;
  height: 100vh;
  padding: 20px;
  display: grid;
  place-items: center;
  @include responsive(sm) {
    padding: 20px 5px;
  }

  .card-wrapper {
    width: 100%;
    max-width: 450px;
  }
}
