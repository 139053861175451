@use '../abstracts' as *;

.update-metric {
  form {
    max-width: 600px;

    .row {
      @include flex;
      margin-bottom: 10px;
      @include responsive(700px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .space {
      width: 10px;
      height: 10px;
    }

    .success {
      color: $color-green;
      font-size: 0.875rem;
      text-align: center;
      margin-bottom: 5px;
    }
  }
}
