@use '../abstracts' as *;

.tabs {
  display: flex;
  align-items: center;
  background-color: $color-primary;
  width: fit-content;
  max-width: 100vw;
  height: 50px;
  margin-bottom: 20px;
  border: 1px solid $color-grey;
  border-right: none;

  & > p {
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0 15px;
    height: 100%;
    display: flex;
    align-items: center;
    width: fit-content;
    border-right: 1px solid $color-grey;
    cursor: pointer;
  }
}
