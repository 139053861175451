@use '../abstracts' as *;

.investments {
  .heading {
    @include flex;
    justify-content: flex-start;
    margin-bottom: 60px;

    .display {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-left: 10px;

      img {
        width: 100%;
      }
    }
  }

  .list {
    overflow-x: scroll;
    border: 1px solid $color-grey;
  }

  .hidden {
    display: none;
  }
}
