@use '../abstracts' as *;

.cagr {
  @include flex;
  align-items: flex-start;
  height: 100%;
  margin-bottom: 40px;

  @include responsive(md) {
    flex-direction: column;
  }

  & > * {
    width: 49%;
    height: 100%;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 20px;
    @include responsive(md) {
      width: 100%;
      height: 50%;
    }
  }

  .meter-wrapper {
    @include responsive(md) {
      margin-bottom: 20px;
    }

    .meter {
      position: relative;
      width: 100%;
      height: 85%;

      .guage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        height: 250px;
        background: linear-gradient($color-white, $color-white) padding-box,
          linear-gradient(to right, #f5513a, #ffbe3c, #2ac5a6) border-box;
        border-radius: 50%;
        border: 35px solid transparent;
        border-bottom-color: $color-white;
      }

      .percentage {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%);
        font-size: 2rem;
        font-weight: 700;
        color: $color-text;
        background-color: $color-white;
        width: 125px;
        text-align: center;
        z-index: 200;
        border: 2px solid red;
        border-radius: 12px;
        padding: 5px 0;
      }

      .needle {
        position: absolute;
        width: 25px;
        height: 100px;
        top: 50%;
        left: 50%;
        transition: all 1s ease-in-out;
        transform-origin: 50% 85%;
      }

      .line {
        width: 0.5px;
        position: absolute;
        background-color: grey;
        top: 50%;
        left: 50%;
        z-index: -100;
      }

      @for $i from 0 to 90 {
        .line-#{$i} {
          @if ($i % 5 == 0) {
            height: 315px;
          } @else {
            height: 280px;
          }
          transform: translate(-50%, -50%) rotate(calc(225deg + $i * 3deg));
        }
      }

      .line-cover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 270px;
        height: 270px;
        background-color: $color-white;
        border-radius: 50%;
        z-index: -100;
      }

      .inverse {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 320px;
        height: 320px;
        border: 35px solid transparent;
        border-radius: 50%;
        z-index: 100;
        border-bottom-color: $color-white;
      }

      .number {
        font-size: 0.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 271px;
        z-index: 100;
      }

      @for $i from 0 to 19 {
        .number-#{$i} {
          transform: translate(-50%, -50%) rotate(calc(225deg + $i * 15deg));
        }
      }

      .date {
        font-size: 0.5rem;
        font-weight: 600;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 305px;
        z-index: 100;
        color: grey;
      }

      @for $i from 0 to 19 {
        .date-#{$i} {
          transform: translate(-50%, -50%) rotate(calc(232deg + $i * 15deg));
        }
      }
    }

    .btn-wrapper {
      @include flex;
      justify-content: center;
      height: 15%;

      button {
        margin: 0 5px;
      }
    }

    .success {
      color: $color-green;
      font-style: italic;
    }
  }

  .activity-wrapper {
    height: 100%;
    min-height: 300px;
    overflow-y: scroll;

    h3 {
      margin-bottom: 20px;
    }

    .activity {
      margin-bottom: 20px;

      & > div {
        @include flex;
        justify-content: flex-start;

        img {
          margin-right: 5px;
        }

        p {
          margin-left: 0;
        }
      }

      p {
        margin-left: 20px;
      }
    }
  }

  .buttons {
    @include flex;
    justify-content: center;
    margin-top: 40px;

    .space {
      width: 50px;
      height: 50px;
    }
  }
}
