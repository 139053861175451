@use '../abstracts' as *;

.data-card {
  flex: 1;
  min-width: 200px;
  height: 150px;
  border: 1px solid $color-grey;
  padding: 15px 10px;
  position: relative;
  @include responsive(md) {
    width: 100%;
  }

  .row {
    @include flex;
    justify-content: flex-start;

    .icon {
      width: 40px;
      margin-right: 5px;
    }

    .title {
      color: $color-text-light;
    }

    .percentage {
      background-color: $color-grey;
      border-radius: 12px;
      padding: 2px 8px;
      font-size: 0.75rem;
      font-weight: 500;
      margin-left: auto;

      &.positive {
        color: #22c6ab;
        background-color: $color-green-light;
      }

      &.negative {
        color: $color-red;
        background-color: $color-pink;
      }
    }
  }

  .value {
    position: absolute;
    line-height: 2rem;
    bottom: 10px;
    right: 20px;
    font-size: 2rem;
    font-weight: 600;

    &.blur {
      color: transparent;
      text-shadow: 0 0 11px $color-black;
    }
  }
}
