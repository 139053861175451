@use '../abstracts' as *;

.profile {
  h1 {
    margin-bottom: 40px;
  }

  .tab-wrapper {
    width: fit-content;
  }
}
